import React  from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Layout/Seo"
import { graphql } from 'gatsby'

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        content
      }
    }
  }
`

export default function PorDefecto ({data}) {

  const page = data.wordpress.page;

  return (
    <Layout>
      <Seo title={page.title} />
      <h1>{page.title}</h1>
      <div dangerouslySetInnerHTML={{__html: page.content}} />
    </Layout>
  )

}